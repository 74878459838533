import React, { ChangeEvent, useState } from 'react';
import styled from 'styled-components';

import { NotVisibleIcon, VisibleIcon } from '../../assets/svg';
import { defaultTheme } from '../../themes';

const Input: React.VFC<InputProps> = ({
  value,
  label,
  error,
  type = 'text',
  isDisabled = false,
  placeholder,
  ...props
}) => {
  const hasError = !!error;
  const [typeInput, setTypeInput] = useState(type);
  const [text, setText] = useState(value || '');
  const changeTypeInput = () => {
    if (typeInput === 'password') {
      setTypeInput('text');
    } else {
      setTypeInput('password');
    }
  };

  const onChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    setText(e.currentTarget.value);
  };

  return (
    <Root>
      {label && <Label>{label}</Label>}
      <Wrapper {...props} $hasError={hasError} $isDisabled={isDisabled}>
        <Field
          $isDisabled={isDisabled}
          type={typeInput}
          placeholder={placeholder}
          disabled={isDisabled}
          {...props}
          value={value}
          onChange={onChangeHandler}
        />
        {type === 'password' && (
          <Icon onClick={changeTypeInput} disabled={Boolean(!text)} type={'button'}>
            {typeInput === 'password' ? (
              <VisibleIcon
                color={Boolean(!text) ? defaultTheme.colors.grayAverage : defaultTheme.colors.grayDarkMode}
              />
            ) : (
              <NotVisibleIcon
                color={Boolean(!text) ? defaultTheme.colors.grayAverage : defaultTheme.colors.grayDarkMode}
              />
            )}
          </Icon>
        )}
      </Wrapper>
      {error && <Error>*{error}</Error>}
    </Root>
  );
};

export interface InputProps {
  placeholder: string;
  type?: string;
  isDisabled?: boolean;
  label?: string;
  error?: string;
  value?: string;
}

interface RootProps {
  $hasError?: boolean;
  $isDisabled?: boolean;
}

const Root = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 32px;
`;

const Label = styled.span`
  margin-left: 20px;
  margin-bottom: 6px;
  width: 100%;
  ${({ theme: { typography } }) => typography.body3};
  color: ${({ theme: { colors } }) => colors.grayDarkMode};
`;
const Wrapper = styled.div<RootProps>`
  box-sizing: border-box;
  min-height: 54px;
  padding: 9px 20px;
  background-color: ${({ theme: { colors } }) => colors.blueExtraLight};
  border: 1px solid ${({ theme: { colors } }) => colors.blueAverage};
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${({ theme: { typography } }) => typography.body3};
  ${({ $hasError, theme: { colors } }) => $hasError && `border-color: ${colors.colorError}`};
  ${({ $isDisabled, theme: { colors } }) =>
    $isDisabled &&
    `
          pointer-events: none;
          border: none;
          background-color: ${colors.grayExtraLight};
          `};
  ${({ theme: { colors } }) => `
    :hover {
      border: 1px solid ${colors.blue}
    };
  `}
`;
const Field = styled.input<RootProps>`
  min-height: 36px;
  width: 100%;
  background-color: ${({ theme: { colors } }) => colors.blueExtraLight};
  border: none;
  outline: none;
  caret-color: ${({ theme: { colors } }) => colors.blue};
  padding: 0;

  ::placeholder {
    color: ${({ theme: { colors } }) => colors.grayAverage};
  }

  ${({ theme: { typography } }) => typography.body3};
  ${({ $isDisabled, theme: { colors } }) =>
    $isDisabled &&
    `          background-color: ${colors.grayExtraLight};
             `};
  ${({ theme: { colors } }) => `
    :focus {
      color: ${colors.grayDark}
    };
    :disabled {
    color: ${colors.grayAverage}
    };
  `}
`;
const Icon = styled.button`
  display: flex;
  align-items: center;
  outline: none;
  border: none;
  background: none;
`;
const Error = styled.span`
  margin-left: 20px;
  margin-top: 6px;
  ${({ theme: { typography } }) => typography.body3};
  color: ${({ theme: { colors } }) => colors.colorError};
`;

export default Input;
