//import { Language } from '../../__generated__/types';

export const AUTH_TOKEN_KEY = 'auth_token';
export const REFRESH_TOKEN_KEY = 'refresh_auth_token';
export const LANGUAGE = 'language';
export const HASH = 'hash';
export const APOLLO_CACHE_PERSIST = 'apollo-cache-persist';

const Storage = window.localStorage;

class StorageClient {
  setObject = (key: string, value: Record<string, unknown>) => {
    Storage.setItem(key, JSON.stringify(value));
  };

  getObject = (key: string) => {
    const value = Storage.getItem(key);
    return value ? JSON.parse(value) : null;
  };

  getAuthToken = (): string | null => {
    return Storage.getItem(AUTH_TOKEN_KEY);
  };

  getRefreshToken = (): string | null => {
    return Storage.getItem(REFRESH_TOKEN_KEY);
  };

  /*setLanguage = (language: Language) => {
    return Storage.setItem(LANGUAGE, language);
  };*/

  getLanguage = () => {
    return Storage.getItem(LANGUAGE);
  };

  setAuthToken = (token: string) => {
    return Storage.setItem(AUTH_TOKEN_KEY, token);
  };

  setRefreshToken = (token: string) => {
    return Storage.setItem(REFRESH_TOKEN_KEY, token);
  };

  removeAuthToken() {
    const token = this.getAuthToken();
    if (token) {
      Storage.removeItem(AUTH_TOKEN_KEY);
    }
  }

  removeRefreshToken() {
    const token = this.getRefreshToken();
    if (token) {
      Storage.removeItem(REFRESH_TOKEN_KEY);
    }
  }

  removeHash = () => {
    return Storage.removeItem(HASH);
  };

  removeApolloCachePersist = () => {
    return Storage.removeItem(APOLLO_CACHE_PERSIST);
  };
}

export const storageClient = new StorageClient();
