import { LanguageSelection, SignIn } from 'pages';
import { FC } from 'react';
import { Redirect, Route, BrowserRouter as Router, Switch } from 'react-router-dom';

import { AuthLayout } from '../layouts/auth';
import { ROUTES } from './index';

export const GuestRouter: FC = () => {
  return (
    <AuthLayout>
      <Router>
        <Switch>
          <Route path={ROUTES['/sign-in']} component={SignIn} />
          <Route path={ROUTES['/language']} component={LanguageSelection} />
          <Route
            render={props => (
              <Redirect
                to={{
                  pathname: ROUTES['/sign-in'],
                  state: { from: props.location },
                }}
              />
            )}
          />
        </Switch>
      </Router>
    </AuthLayout>
  );
};
