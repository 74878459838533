/* eslint-disable no-unused-vars */
import useAuthContext from 'libs/Apollo/hooks/useAuthContext';
import { FC, createContext } from 'react';
import { BrowserRouter } from 'react-router-dom';

import { GuestRouter } from './GuestRouter';
import { UserRouter } from './UserRouter';

export enum RouterCase {
  GUEST = 'GUEST',
  USER = 'USER',
}

type AuthContextProps = ReturnType<typeof useAuthContext>['authContext'];

export const AuthContext = createContext<Partial<AuthContextProps>>({});

const AppRouter: FC = () => {
  const { authContext, isAuth } = useAuthContext();

  const RouterSwitch = {
    [RouterCase.GUEST]: !isAuth,
    [RouterCase.USER]: isAuth,
  };

  let Router;
  const route = Object.keys(RouterSwitch).find(key => RouterSwitch[key as RouterCase]);

  switch (route) {
    case RouterCase.GUEST:
      Router = <GuestRouter />;
      break;
    case RouterCase.USER:
      Router = <UserRouter />;
      break;
    default:
      Router = <GuestRouter />;
      break;
  }

  return (
    <BrowserRouter>
      <AuthContext.Provider value={authContext}>{Router}</AuthContext.Provider>
    </BrowserRouter>
  );
};

export default AppRouter;
