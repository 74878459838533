import { useEffect, useState } from 'react';
import styled from 'styled-components';

import { DoctorInvitationModel, Maybe } from '../../__generated__/types';
import { useRemoveDoctor } from '../../common/mutation/__generated__/remove-doctor';
import { useSearchDoctors } from '../../common/query/__generated__/search-doctors';
import { modalVar } from '../../libs/Apollo';
import { defaultTheme } from '../../themes';
import {
  Button,
  CheckModalTemplate,
  Doctor,
  Loader,
  ModalAddingDoctor,
  ModalContainer,
  ModalWithButton,
  Scrollbar,
  Search,
} from '../../ui';

export const DoctorsList = () => {
  //modals
  const [isOpenModalDeletingDoctor, setIsOpenModalDeletingDoctor] = useState(false);
  const [isOpenModalAddingDoctor, setIsOpenModalAddingDoctor] = useState(false);
  const [isOpenModalInvitation, setIsOpenModalInvitation] = useState(false);
  const openModalAddingDoctor = () => {
    setIsOpenModalAddingDoctor(true);
    modalVar({ isOpenModal: true });
  };
  const closeModalAddingDoctor = () => {
    setIsOpenModalAddingDoctor(false);
    modalVar({ isOpenModal: false });
  };
  const openModalInvitation = () => {
    setIsOpenModalInvitation(true);
    modalVar({ isOpenModal: true });
  };
  const closeModalInvitation = () => {
    setIsOpenModalInvitation(false);
    modalVar({ isOpenModal: false });
  };
  const openModalDeletingDoctor = () => {
    setIsOpenModalDeletingDoctor(true);
    modalVar({ isOpenModal: true });
  };
  const closeModalDeletingDoctor = () => {
    setIsOpenModalDeletingDoctor(false);
    modalVar({ isOpenModal: false });
  };

  const isOpenModal = isOpenModalAddingDoctor || isOpenModalDeletingDoctor || isOpenModalInvitation;

  //search doctors
  const [inputValue, setInputValue] = useState('');
  const { data: doctorsList, loading: loadingSearchDoctors, fetchMore, refetch } = useSearchDoctors({
    variables: {
      filter: inputValue,
      first: 10,
    },
    fetchPolicy: 'network-only',
    pollInterval: 300000,
  });

  const [doctors, setDoctors] = useState<DoctorInvitationModel[]>([]);
  const [cursor, setCursor] = useState<Maybe<string> | undefined>(undefined);
  const [hasNextPage, setHasNextPage] = useState<boolean>(false);
  useEffect(() => {
    if (doctorsList) {
      setDoctors(doctorsList?.searchDoctors.nodes);
      setCursor(doctorsList?.searchDoctors.pageInfo.endCursor);
      setHasNextPage(doctorsList?.searchDoctors.pageInfo.hasNextPage);
    }
  }, [doctorsList]);

  const onLoadMoreDoctors = async () => {
    if (hasNextPage) {
      const { data } = await fetchMore({
        variables: {
          after: cursor,
        },
      });
      setDoctors([...doctors, ...data.searchDoctors.nodes]);
      if (data.searchDoctors.pageInfo.hasNextPage) {
        setCursor(data.searchDoctors.pageInfo.endCursor);
      } else {
        setCursor(undefined);
      }
      setHasNextPage(data.searchDoctors.pageInfo.hasNextPage);
    }
  };

  //search field
  const [isFocusedSearch, setIsFocusedSearch] = useState(false);
  const onFocus = () => setIsFocusedSearch(true);
  const onBlur = () => setIsFocusedSearch(false);

  //delete doctor
  const [doctorIdForDeleting, setDoctorIdForDeleting] = useState<string>('');
  const [removeDoctor, { loading: loadingDeleteDoctor }] = useRemoveDoctor({
    variables: {
      input: {
        doctorId: doctorIdForDeleting,
      },
    },
  });
  const deleteDoctor = async () => {
    const res = await removeDoctor();
    if (res.data?.doctorRemoveById.success) {
      await refetch();
    }
  };

  //add doctor
  const addNewDoctor = async () => {
    closeModalAddingDoctor();
    await refetch();
  };
  const returnDoctor = (doctor: DoctorInvitationModel) => {
    return (
      <Doctor
        email={doctor.email}
        key={doctor.id}
        isInviteAccepted={!!doctor.isInviteAccepted}
        openModal={openModalDeletingDoctor}
        id={doctor.doctorId || ''}
        setDoctorIdForDeleting={setDoctorIdForDeleting}
      />
    );
  };

  return (
    <Root>
      <Container $isOpenModal={isOpenModal}>
        <Content>
          <Title>Список врачей</Title>
          <SearchWrapper>
            <Search
              placeholder="Поиск"
              onFocus={onFocus}
              onBlur={onBlur}
              inputValue={inputValue}
              setInputValue={setInputValue}
            />
            <ButtonWrapper>
              <Button variant="primary" label="Добавить врача" width={270} onClick={openModalAddingDoctor} />
            </ButtonWrapper>
          </SearchWrapper>
          <MainContainer>
            {loadingSearchDoctors ? (
              <CenteringWrapper>
                <Loader color={defaultTheme.colors.grayDarkMode} />
              </CenteringWrapper>
            ) : isFocusedSearch ? (
              inputValue.length === 0 ? (
                <CenteringWrapper>
                  <NoDoctors>Начните вводить адрес электронной почты врача, чтобы начать поиск</NoDoctors>
                </CenteringWrapper>
              ) : doctors.length > 0 ? (
                <ListWrapper>
                  <Scrollbar onLoadMore={onLoadMoreDoctors}>{doctors.map(doctor => returnDoctor(doctor))}</Scrollbar>
                </ListWrapper>
              ) : (
                <CenteringWrapper>
                  <NoDoctors>Врачей не найдено</NoDoctors>
                </CenteringWrapper>
              )
            ) : doctors.length > 0 ? (
              <ListWrapper>
                <Scrollbar onLoadMore={onLoadMoreDoctors}>{doctors.map(doctor => returnDoctor(doctor))}</Scrollbar>
              </ListWrapper>
            ) : (
              <CenteringWrapper>
                <NoDoctors>
                  У Вас нет врачей
                  <br />
                  Выберите их из базы данных или добавьте нового
                </NoDoctors>
              </CenteringWrapper>
            )}
          </MainContainer>
        </Content>
      </Container>

      {isOpenModalAddingDoctor && (
        <ModalContainer closeModal={closeModalAddingDoctor}>
          <ModalAddingDoctor
            closeModal={closeModalAddingDoctor}
            openNextModal={openModalInvitation}
            addNewDoctor={addNewDoctor}
          />
        </ModalContainer>
      )}
      {isOpenModalInvitation && (
        <ModalContainer closeModal={closeModalInvitation}>
          <CheckModalTemplate text="Приглашение отправлено на указанную электронную почту" />
        </ModalContainer>
      )}
      {isOpenModalDeletingDoctor && (
        <ModalContainer closeModal={closeModalDeletingDoctor}>
          <ModalWithButton
            closeModal={closeModalDeletingDoctor}
            question="Вы уверены, что хотите удалить врача?"
            description="Врач будет удален из базы данных"
            onClick={deleteDoctor}
            loading={loadingDeleteDoctor}
          />
        </ModalContainer>
      )}
    </Root>
  );
};

type StyleType = {
  $isOpenModal: boolean;
};

const Root = styled.div`
  width: 100%;
`;
const Container = styled.div<StyleType>`
  width: 100%;
  height: calc(100vh - 100px);
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  z-index: 1;
  background-color: ${({ theme: { colors } }) => colors.blueExtraLight};
  ${({ $isOpenModal }) => $isOpenModal && `filter: blur(3px)`}
`;
const Content = styled.div`
  width: 1280px;
  height: calc(100vh - 180px);
  background-color: ${({ theme: { colors } }) => colors.white};
  box-sizing: border-box;
  border: 1px solid ${({ theme: { colors } }) => colors.blueExtraLight};
  box-shadow: 0 6px 30px #e4ecf7;
  border-radius: 10px;
  padding: 31px;
  margin: 40px 0;
`;
const Title = styled.span`
  color: ${({ theme: { colors } }) => colors.grayDark};
  ${({ theme: { typography } }) => typography.title2};
  line-height: 44px;
`;
const SearchWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
`;
const ButtonWrapper = styled.div`
  margin-left: 32px;
`;
const MainContainer = styled.div`
  width: 100%;
  height: calc(100vh - 180px - 214px);
`;
const CenteringWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: inherit;
`;
const NoDoctors = styled.p`
  width: 256px;
  color: ${({ theme: { colors } }) => colors.grayDarkMode};
  ${({ theme: { typography } }) => typography.title3};
  text-align: center;
`;
const ListWrapper = styled.div`
  width: calc(100% + 32px);
  height: calc(100% + 16px);
`;
