import styled from 'styled-components';
import { defaultTheme } from 'themes';

import { Maybe } from '../../__generated__/types';
import { AccountBoxIcon, TrashIcon, VerticalLineIcon } from '../../assets/svg';

export type DoctorPropsType = {
  email: Maybe<string> | undefined;
  openModal?: () => void;
  id: string;
  isInviteAccepted: boolean;
  setDoctorIdForDeleting: (id: string) => void;
};

function Doctor({ email, openModal, isInviteAccepted, id, setDoctorIdForDeleting }: DoctorPropsType) {
  const onClickHandler = () => {
    setDoctorIdForDeleting(id);
    if (openModal) {
      openModal();
    }
  };
  return (
    <Root>
      <Wrapper>
        <AccountBoxIcon size={'42'} />
        <Vertical>
          <VerticalLineIcon color={isInviteAccepted ? defaultTheme.colors.blue : defaultTheme.colors.grayAverage} />
        </Vertical>
        <DoctorName>{email}</DoctorName>
      </Wrapper>
      {isInviteAccepted && (
        <Trash onClick={onClickHandler}>
          <TrashIcon />
        </Trash>
      )}
    </Root>
  );
}

export default Doctor;

const Root = styled.div`
  width: calc(100% - 32px);
  height: 96px;
  box-sizing: border-box;
  border-radius: 10px;
  background-color: ${({ theme: { colors } }) => colors.grayExtraLight};
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 32px;
  }
`;
const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;
const Vertical = styled.div`
  margin: 4px 16px 0;
`;
const DoctorName = styled.span`
  color: ${({ theme: { colors } }) => colors.grayDark};
  ${({ theme: { typography } }) => typography.title3};
`;
const Trash = styled.div`
  margin-right: 2px;
  cursor: pointer;
`;
