import * as Apollo from '@apollo/client';
import { DocumentNode } from 'graphql';

import * as Types from '../../../__generated__/types';

const defaultOptions = {};
export type AddDoctorVariables = Types.Exact<{
  input: Types.DoctorEmailSignUpSendLinkInput;
}>;

export type AddDoctor = {
  __typename: 'Mutation';
  doctorEmailSignUpSendCode: {
    __typename: 'DoctorEmailSignUpSendCodePayload';
    problem?:
      | { __typename: 'ExistEmailProblem'; message: string }
      | { __typename: 'TooManyRequestsProblem'; message: string }
      | null
      | undefined;
  };
};

export const AddDoctorDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'AddDoctor' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'DoctorEmailSignUpSendLinkInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'doctorEmailSignUpSendCode' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'problem' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'InlineFragment',
                        typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ExistEmailProblem' } },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'message' } }],
                        },
                      },
                      {
                        kind: 'InlineFragment',
                        typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'TooManyRequestsProblem' } },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'message' } }],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode;
export type AddDoctorMutationFn = Apollo.MutationFunction<AddDoctor, AddDoctorVariables>;

/**
 * __useAddDoctor__
 *
 * To run a mutation, you first call `useAddDoctor` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddDoctor` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addDoctor, { data, loading, error }] = useAddDoctor({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddDoctor(baseOptions?: Apollo.MutationHookOptions<AddDoctor, AddDoctorVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddDoctor, AddDoctorVariables>(AddDoctorDocument, options);
}
export type AddDoctorHookResult = ReturnType<typeof useAddDoctor>;
export type AddDoctorMutationResult = Apollo.MutationResult<AddDoctor>;
export type AddDoctorMutationOptions = Apollo.BaseMutationOptions<AddDoctor, AddDoctorVariables>;
