import { InMemoryCache, createHttpLink, makeVar, split } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { WebSocketLink } from '@apollo/client/link/ws';
import { getMainDefinition } from '@apollo/client/utilities';
import { storageClient } from 'libs/StorageClient';
import { API_URL_GRAPHQL, WS_API_URL } from 'utils/constants';

const httpLink = createHttpLink({
  uri: API_URL_GRAPHQL,
});

const wsAuthLink = new WebSocketLink({
  uri: WS_API_URL,
  options: {
    reconnect: true,
    lazy: true, // for supports async connectionParams
    connectionParams: async () => {
      const token = storageClient.getAuthToken() || sessionStorage.getItem('token');
      return {
        Authorization: token ? `Bearer ${token}` : null,
      };
    },
  },
});

const authLink = setContext(async (_, { headers }) => {
  const token = storageClient.getAuthToken() || sessionStorage.getItem('token');

  return {
    ...headers,
    headers: {
      authorization: token ? `Bearer ${token}` : null,
    },
  };
});

const httpAuthLink = authLink.concat(httpLink);

const link = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
  },
  wsAuthLink,
  httpAuthLink,
);

export const modalVar = makeVar<{ isOpenModal: boolean }>({ isOpenModal: false });

const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        isOpenModal: {
          read(value, opts) {
            return modalVar();
          },
        },
      },
    },
  },
});

const storage = storageClient;

const logOut = async () => {
  storageClient.removeAuthToken();
  storageClient.removeRefreshToken();
  await cache.reset();
};

const refetchAll = async () => {
  cache.reset();
};

const apollo = { link, cache, storage, logOut, refetchAll };

export default apollo;
