import * as Apollo from '@apollo/client';
import { DocumentNode } from 'graphql';

import * as Types from '../../../__generated__/types';

const defaultOptions = {};
export type EmailSignInVariables = Types.Exact<{
  input: Types.HospitalManagerEmailSignInInput;
}>;

export type EmailSignIn = {
  __typename: 'Mutation';
  hospitalManagerEmailSignIn: {
    __typename: 'HospitalManagerEmailSignInPayload';
    token?: string | null | undefined;
    refreshToken?: string | null | undefined;
    problem?: { __typename: 'NotExistEmailProblem'; message: string } | null | undefined;
    user?:
      | {
          __typename: 'MeHospitalManagerModel';
          id: string;
          email?: string | null | undefined;
          role?: Types.PatientRole | null | undefined;
        }
      | null
      | undefined;
  };
};

export const EmailSignInDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'EmailSignIn' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'HospitalManagerEmailSignInInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'hospitalManagerEmailSignIn' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'problem' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'message' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'role' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'token' } },
                { kind: 'Field', name: { kind: 'Name', value: 'refreshToken' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode;
export type EmailSignInMutationFn = Apollo.MutationFunction<EmailSignIn, EmailSignInVariables>;

/**
 * __useEmailSignIn__
 *
 * To run a mutation, you first call `useEmailSignIn` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEmailSignIn` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [emailSignIn, { data, loading, error }] = useEmailSignIn({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEmailSignIn(baseOptions?: Apollo.MutationHookOptions<EmailSignIn, EmailSignInVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<EmailSignIn, EmailSignInVariables>(EmailSignInDocument, options);
}
export type EmailSignInHookResult = ReturnType<typeof useEmailSignIn>;
export type EmailSignInMutationResult = Apollo.MutationResult<EmailSignIn>;
export type EmailSignInMutationOptions = Apollo.BaseMutationOptions<EmailSignIn, EmailSignInVariables>;
