import React from 'react';
import styled from 'styled-components';

import { CheckIcon } from '../../assets/svg';

function CheckModalTemplate({ text }: { text: string }) {
  return (
    <Root>
      <CheckIcon />
      <Text>{text}</Text>
    </Root>
  );
}

export default CheckModalTemplate;

const Root = styled.div`
  width: 420px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${({ theme: { colors } }) => colors.white};
  border: 1px solid ${({ theme: { colors } }) => colors.blueExtraLight};
  box-sizing: border-box;
  box-shadow: 0 6px 30px #e4ecf7;
  border-radius: 10px;
  padding: 49px 37px;
`;
const Text = styled.div`
  ${({ theme: { typography } }) => typography.title2};
  color: ${({ theme: { colors } }) => colors.grayDark};
  text-align: center;
  letter-spacing: -0.165px;
  margin-top: 10px;
`;
