import { Form, FormikProvider, useFormik } from 'formik';
import React from 'react';
import styled from 'styled-components';
import * as Yup from 'yup';

import { CrossIcon } from '../../assets/svg';
import { useAddDoctor } from '../../common/mutation/__generated__/add-doctor';
import { useDoctorEmailIsUniq } from '../../common/query/__generated__/doctor-email-is-uniq';
import { defaultTheme } from '../../themes';
import { Button } from '../Button';
import { Input } from '../Input';

type Props = {
  closeModal: () => void;
  openNextModal: () => void;
  addNewDoctor: () => void;
};

type Values = {
  email: string;
};

function ModalAddingDoctor({ closeModal, openNextModal, addNewDoctor }: Props) {
  const [addDoctor, { loading, error }] = useAddDoctor();

  const onClickHandler = () => {
    addNewDoctor();
    openNextModal();
  };
  let errorResponse = '';
  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .required('Пожалуйста, введите e-mail')
        .email('E-mail должен соответствовать типу example@mail.com')
        .matches(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i, 'E-mail должен соответствовать типу example@mail.com'),
    }),
    onSubmit: async (values: Values) => {
      const res = await addDoctor({
        variables: {
          input: {
            email: values.email,
          },
        },
      });
      if (!res.data?.doctorEmailSignUpSendCode.problem) {
        onClickHandler();
      } else {
        errorResponse = res.data?.doctorEmailSignUpSendCode.problem.message;
      }
    },
  });

  //email is uniq
  const { data } = useDoctorEmailIsUniq({
    variables: {
      email: formik.values.email,
    },
  });
  errorResponse =
    data?.doctorEmailIsUniq !== undefined && !data?.doctorEmailIsUniq
      ? 'Врач с таким адресом электронной почты уже существует'
      : '';

  return (
    <Root onClick={e => e.stopPropagation()}>
      <Header>
        <TextHeader>Добавить врача</TextHeader>
        <CloseIcon onClick={closeModal}>
          <CrossIcon color={defaultTheme.colors.grayDarkMode} />
        </CloseIcon>
      </Header>
      <FormWrapper>
        <FormikProvider value={formik}>
          <Form onSubmit={formik.handleSubmit}>
            <Input
              placeholder="Введите E-mail"
              label="E-mail"
              error={(formik.touched.email ? formik.errors.email : '') || errorResponse || error?.message}
              {...formik.getFieldProps('email')}
            />
            <Button
              variant="primary"
              label="Подтвердить"
              type="submit"
              isDisabled={!(formik.isValid && formik.dirty) || Boolean(errorResponse)}
              isLoading={loading}
            />
          </Form>
        </FormikProvider>
      </FormWrapper>
    </Root>
  );
}

export default ModalAddingDoctor;

const Root = styled.div`
  width: 420px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${({ theme: { colors } }) => colors.white};
  border: 1px solid ${({ theme: { colors } }) => colors.blueExtraLight};
  box-sizing: border-box;
  box-shadow: 0 6px 30px #e4ecf7;
  border-radius: 10px;
  padding: 39px;
  position: relative;
  z-index: 100;
`;
const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 32px;
  width: 100%;
`;
const TextHeader = styled.span`
  ${({ theme: { typography } }) => typography.title2};
  color: ${({ theme: { colors } }) => colors.grayDark};
`;
const CloseIcon = styled.div`
  padding: 5px;
  cursor: pointer;
`;
const FormWrapper = styled.div`
  width: 100%;
`;
