import React, { ReactElement } from 'react';
import styled from 'styled-components';

type Props = {
  children: ReactElement;
  closeModal: () => void;
};

function ModalContainer({ children, closeModal }: Props) {
  return <Root onClick={closeModal}>{children}</Root>;
}

export default ModalContainer;

const Root = styled.div`
  position: fixed;
  background: #77799850;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
`;
