import * as Apollo from '@apollo/client';
import { DocumentNode } from 'graphql';

import * as Types from '../../../__generated__/types';

const defaultOptions = {};
export type DoctorEmailIsUniqVariables = Types.Exact<{
  email: Types.Scalars['String'];
}>;

export type DoctorEmailIsUniq = { __typename: 'Query'; doctorEmailIsUniq: boolean };

export const DoctorEmailIsUniqDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'DoctorEmailIsUniq' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'email' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'doctorEmailIsUniq' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'email' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'email' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode;

/**
 * __useDoctorEmailIsUniq__
 *
 * To run a query within a React component, call `useDoctorEmailIsUniq` and pass it any options that fit your needs.
 * When your component renders, `useDoctorEmailIsUniq` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDoctorEmailIsUniq({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useDoctorEmailIsUniq(
  baseOptions: Apollo.QueryHookOptions<DoctorEmailIsUniq, DoctorEmailIsUniqVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<DoctorEmailIsUniq, DoctorEmailIsUniqVariables>(DoctorEmailIsUniqDocument, options);
}
export function useDoctorEmailIsUniqLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<DoctorEmailIsUniq, DoctorEmailIsUniqVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<DoctorEmailIsUniq, DoctorEmailIsUniqVariables>(DoctorEmailIsUniqDocument, options);
}
export type DoctorEmailIsUniqHookResult = ReturnType<typeof useDoctorEmailIsUniq>;
export type DoctorEmailIsUniqLazyQueryHookResult = ReturnType<typeof useDoctorEmailIsUniqLazyQuery>;
export type DoctorEmailIsUniqQueryResult = Apollo.QueryResult<DoctorEmailIsUniq, DoctorEmailIsUniqVariables>;
