import { useQuery } from '@apollo/client';
import React, { useState } from 'react';
import useOnclickOutside from 'react-cool-onclickoutside';
import styled from 'styled-components';

import { AccountBoxIcon, HeartbeatLogoIcon, LogoutIcon } from '../../assets/svg';
import useAuthContext from '../../libs/Apollo/hooks/useAuthContext';
import { GET_MODAL_STATUS } from '../../libs/Apollo/query/get-modal-status';

type PropsType = {
  isAuth?: boolean;
};

function Header({ isAuth = true }: PropsType) {
  const [isOpenMenu, setIsOpenMenu] = useState(false);
  const { authContext } = useAuthContext();

  const ref = useOnclickOutside(() => {
    setIsOpenMenu(false);
  });

  const { data } = useQuery(GET_MODAL_STATUS);
  const isOpenModal: boolean = data.isOpenModal.isOpenModal;

  const logout = async () => {
    await authContext.signOut();
    window.location.reload();
  };

  return (
    <Root $isOpenModal={isOpenModal}>
      <Container>
        <HeartbeatLogoIcon />
        {isAuth && (
          <Wrapper>
            <Div ref={ref}>
              <UserIcon onClick={() => setIsOpenMenu(!isOpenMenu)}>
                <AccountBoxIcon size={'38'} />
              </UserIcon>
              {isOpenMenu && (
                <Menu>
                  <MenuItem onClick={logout}>
                    <LogoutIcon />
                    <MenuItemTitle>Выход</MenuItemTitle>
                  </MenuItem>
                </Menu>
              )}
            </Div>
          </Wrapper>
        )}
      </Container>
    </Root>
  );
}

export default Header;

const Root = styled.header<{ $isOpenModal: boolean }>`
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: center;
  background-color: ${({ theme: { colors } }) => colors.white};
  ${({ $isOpenModal }) => $isOpenModal && `filter: blur(3px)`}
`;
const Container = styled.div`
  width: 1280px;
  height: 100%;
  padding: 0 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const UserIcon = styled.div`
  position: relative;
`;
const Menu = styled.div`
  width: 176px;
  background-color: ${({ theme: { colors } }) => colors.white};
  border: 1px solid ${({ theme: { colors } }) => colors.blueLight};
  box-sizing: border-box;
  border-radius: 10px;
  position: absolute;
  z-index: 100;
  top: 56px;
  left: -123px;
`;
const MenuItem = styled.div`
  display: flex;
  align-items: center;
  padding: 0 20px;
  cursor: pointer;

  &:first-child {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }

  &:last-child {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }

  &:hover {
    background-color: ${({ theme: { colors } }) => colors.blueLight};
  }
`;
const MenuItemTitle = styled.span`
  ${({ theme: { typography } }) => typography.body3}
  color: ${({ theme: { colors } }) => colors.grayDark};
  margin: 10px;
`;
const Div = styled.div`
  position: relative;
`;
