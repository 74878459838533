import { DoctorsList } from 'pages';
import { FC } from 'react';
import { Redirect, Route, BrowserRouter as Router, Switch } from 'react-router-dom';

import { UserLayout } from '../layouts/user';
import { ROUTES } from './index';

export const UserRouter: FC = () => {
  return (
    <UserLayout>
      <Router>
        <Switch>
          <Route path={ROUTES['/doctors']} component={DoctorsList} />
          <Redirect from="/" to={ROUTES['/doctors']} />
        </Switch>
      </Router>
    </UserLayout>
  );
};
