import { useReactiveVar } from '@apollo/client';
import { storageClient } from 'libs/StorageClient';
import React, { useEffect } from 'react';

import { isAuthVar, useSetupApolloClient } from './useSetupApolloClient';

const useAuthContext = () => {
  const client = useSetupApolloClient();
  const isAuth = useReactiveVar(isAuthVar);

  useEffect(() => {
    const init = async () => {
      const token = storageClient.getAuthToken();
      const sessionToken = sessionStorage.getItem('token');
      if (token || sessionToken) {
        isAuthVar(true);
      }
    };
    init();
  }, []);

  const authContext = React.useMemo(
    () => ({
      signIn: (token: string, refreshToken: string) => {
        storageClient.setAuthToken(token);
        storageClient.setRefreshToken(refreshToken);
        isAuthVar(true);
      },
      signInWithoutRemember: (token: string, refreshToken: string) => {
        sessionStorage.setItem('token', token);
        sessionStorage.setItem('refreshToken', refreshToken);
        isAuthVar(true);
      },
      signUp: (token: string, refreshToken: string) => {
        storageClient.setAuthToken(token);
        storageClient.setRefreshToken(refreshToken);
        isAuthVar(true);
      },
      signOut: async () => {
        storageClient.removeAuthToken();
        storageClient.removeRefreshToken();
        sessionStorage.removeItem('token');
        sessionStorage.removeItem('refreshToken');
        await client?.clearStore();
        isAuthVar(false);
      },
    }),
    [client],
  );

  return { authContext, isAuth };
};

export default useAuthContext;
