import React, { ChangeEvent } from 'react';
import styled from 'styled-components';

import { SearchIcon } from '../../assets/svg';

function Search({ placeholder, inputValue, setInputValue, ...props }: SearchProps) {
  const onChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    if (setInputValue) {
      setInputValue(e.currentTarget.value);
    }
  };
  return (
    <Root>
      <Wrapper {...props}>
        <SearchIcon />
        <Field
          placeholder={placeholder}
          {...props}
          onFocus={props.onFocus}
          onBlur={props.onBlur}
          value={inputValue}
          onChange={onChangeHandler}
        />
      </Wrapper>
    </Root>
  );
}

export interface SearchProps {
  placeholder: string;
  onFocus?: () => void;
  onBlur?: () => void;
  inputValue?: string;
  setInputValue?: (value: React.SetStateAction<string>) => void;
}

const Root = styled.div`
  display: flex;
  flex-direction: column;
  width: 406px;
  margin-bottom: 32px;
`;

const Wrapper = styled.div`
  box-sizing: border-box;
  height: 54px;
  padding: 10px 20px;
  background-color: ${({ theme: { colors } }) => colors.blueExtraLight};
  border: 1px solid ${({ theme: { colors } }) => colors.blueAverage};
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${({ theme: { typography } }) => typography.body3};
  ${({ theme: { colors } }) => `
    :hover {
      border: 1px solid ${colors.blue}
    };
  `}
`;
const Field = styled.input`
  min-height: 36px;
  width: 100%;
  background-color: ${({ theme: { colors } }) => colors.blueExtraLight};
  border: none;
  outline: none;
  caret-color: ${({ theme: { colors } }) => colors.blue};
  margin-left: 14px;

  ::placeholder {
    color: ${({ theme: { colors } }) => colors.grayAverage};
  }

  ${({ theme: { typography } }) => typography.body3};

  ${({ theme: { colors } }) => `
    :focus {
      color: ${colors.grayDark}
    };
    :disabled {
    color: ${colors.grayAverage}
    };
  `}
`;

export default Search;
