import * as Apollo from '@apollo/client';
import { DocumentNode } from 'graphql';

import * as Types from '../../../__generated__/types';
import { DoctorInvitation } from '../../fragments/__generated__/doctor.fragment';

const defaultOptions = {};
export type SearchDoctorsVariables = Types.Exact<{
  after?: Types.Maybe<Types.Scalars['String']>;
  filter: Types.Scalars['String'];
  first?: Types.Maybe<Types.Scalars['Int']>;
}>;

export type SearchDoctors = {
  __typename: 'Query';
  searchDoctors: {
    __typename: 'DoctorConnection';
    nodes: Array<{
      __typename: 'DoctorInvitationModel';
      id: string;
      doctorId?: string | null | undefined;
      role?: Types.PatientRole | null | undefined;
      email?: string | null | undefined;
      language?: Types.Language | null | undefined;
      createdAt?: any | null | undefined;
      deletedAt?: any | null | undefined;
      isInviteAccepted?: boolean | null | undefined;
      updatedAt?: any | null | undefined;
      hospital?: { __typename: 'HospitalModel'; id: string; name: string; patientsLimit: number } | null | undefined;
    }>;
    pageInfo: { __typename: 'PageInfo'; endCursor?: string | null | undefined; hasNextPage: boolean };
  };
};

export const SearchDoctorsDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'SearchDoctors' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'after' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'first' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'searchDoctors' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'after' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'after' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'first' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'first' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nodes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'DoctorInvitation' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pageInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'endCursor' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'hasNextPage' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...DoctorInvitation.definitions,
  ],
} as unknown) as DocumentNode;

/**
 * __useSearchDoctors__
 *
 * To run a query within a React component, call `useSearchDoctors` and pass it any options that fit your needs.
 * When your component renders, `useSearchDoctors` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchDoctors({
 *   variables: {
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *      first: // value for 'first'
 *   },
 * });
 */
export function useSearchDoctors(baseOptions: Apollo.QueryHookOptions<SearchDoctors, SearchDoctorsVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SearchDoctors, SearchDoctorsVariables>(SearchDoctorsDocument, options);
}
export function useSearchDoctorsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<SearchDoctors, SearchDoctorsVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SearchDoctors, SearchDoctorsVariables>(SearchDoctorsDocument, options);
}
export type SearchDoctorsHookResult = ReturnType<typeof useSearchDoctors>;
export type SearchDoctorsLazyQueryHookResult = ReturnType<typeof useSearchDoctorsLazyQuery>;
export type SearchDoctorsQueryResult = Apollo.QueryResult<SearchDoctors, SearchDoctorsVariables>;
