//import { Language } from '../__generated__/types';

export const API_URL_GRAPHQL = process.env.REACT_APP_API_BASE_URL_GRAPHQL!;
export const API_URL = process.env.REACT_APP_API_BASE_URL!;
export const WS_API_URL = process.env.REACT_APP_WS_API_BASE_URL!;

export const languages = [
  { label: 'Русский', value: 'Language.Ru' },
  { label: 'English', value: 'Language.En' },
  { label: 'Deutsch', value: 'Language.De' },
  { label: 'Qazaq tili', value: 'Language.Kz' },
];
