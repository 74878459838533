import { ApolloProvider } from '@apollo/client/react';
import { useSetupApolloClient } from 'libs/Apollo/hooks';
import AppRouter from 'routers/AppRouter';
import { ThemeProvider } from 'styled-components';

import { defaultTheme } from './themes';

export default function App() {
  const client = useSetupApolloClient();

  if (!client) {
    return null;
  }

  return (
    <ApolloProvider client={client}>
      <ThemeProvider theme={defaultTheme}>
        <AppRouter />
      </ThemeProvider>
    </ApolloProvider>
  );
}
