import { DocumentNode } from 'graphql';

import * as Types from '../../../__generated__/types';

export type DoctorInvitation = {
  __typename: 'DoctorInvitationModel';
  id: string;
  doctorId?: string | null | undefined;
  role?: Types.PatientRole | null | undefined;
  email?: string | null | undefined;
  language?: Types.Language | null | undefined;
  createdAt?: any | null | undefined;
  deletedAt?: any | null | undefined;
  isInviteAccepted?: boolean | null | undefined;
  updatedAt?: any | null | undefined;
  hospital?: { __typename: 'HospitalModel'; id: string; name: string; patientsLimit: number } | null | undefined;
};

export const DoctorInvitation = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DoctorInvitation' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DoctorInvitationModel' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'doctorId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'role' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'language' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'deletedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isInviteAccepted' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'hospital' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'patientsLimit' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode;
