import './styles.css';

import React from 'react';
import ScrollArea from 'react-scrollbar';

import { defaultTheme } from '../../themes';

type PositionScroll = {
  containerHeight: number;
  realHeight: number;
  topPosition: number;
};
type Props = {
  onLoadMore?: () => void;
};

const Scrollbar = ({ onLoadMore, ...props }: Props & ScrollAreaProps) => {
  const handleScroll = (scrollData: PositionScroll) => {
    if (scrollData.topPosition >= scrollData.realHeight - scrollData.containerHeight - 150) {
      if (onLoadMore) {
        onLoadMore();
      }
    }
  };

  return (
    <ScrollArea
      className="area"
      smoothScrolling={true}
      horizontal={false}
      onScroll={handleScroll}
      verticalContainerStyle={{ backgroundColor: 'transparent' }}
      verticalScrollbarStyle={{ width: 10, backgroundColor: defaultTheme.colors.blueLight, borderRadius: 4 }}>
      {props.children}
    </ScrollArea>
  );
};

export default Scrollbar;
